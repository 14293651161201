<template>
  <div class="px-2 game-score text-center">
    <!-- Проверяем, что team_number не равен 0 и определяем победителя -->
    <div v-if="game.team_number !== 0">
      <!-- Подсвечиваем score_side1, если team_number = 1 -->
      <div class="position-relative" v-if="game.team_number === 1">
        <div class="text-nowrap">
          <span :class="[dataSport.textClass, { 'text-white': game.team_number !== 1 }]">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{ game.score_side2 }}</span>
        </div>
      </div>
      <!-- Подсвечиваем score_side2, если team_number = 2 -->
      <div class="position-relative" v-else-if="game.team_number === 2">
        <div class="text-nowrap">
          <span class="text-white">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span :class="[dataSport.textClass, { 'text-white': game.team_number !== 2 }]">{{ game.score_side2 }}</span>
        </div>
      </div>
    </div>
    <!-- Если team_number равен 0, стандартный вывод -->
    <div v-else>
      <div class="position-relative" v-if="game.score_side1 > game.score_side2">
        <div class="text-nowrap">
          <span :class="dataSport.textClass">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{ game.score_side2 }}</span>
        </div>
      </div>
      <div class="position-relative" v-else-if="game.score_side1 < game.score_side2">
        <div class="text-nowrap">
          <span class="text-white">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span :class="dataSport.textClass">{{ game.score_side2 }}</span>
        </div>
      </div>
      <div class="position-relative" v-else-if="game.score_side1 === game.score_side2 && game.state !== 'upcoming'">
        <div class="text-nowrap">
          <span class="text-white">{{ game.score_side1 }}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{ game.score_side2 }}</span>
        </div>
      </div>
      <div v-else>
        <b class="separator text-white">-</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MlbGameScore",
  props: ["game", "dataSport"],
};
</script>

<style scoped>
.game-score {
  letter-spacing: 2px;
  font-size: 0.8rem;
}
</style>
